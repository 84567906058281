<template>
  <div class="position-relative head-of-layout-div">
    <div class="d-flex flex-column-fluid flex-row">
      <div class="sidebar-padding">
        <Sidebar/>
      </div>
      <div class="w-100 right-side">
        <div class="header">
          <Header/>
        </div>
        <Transition>
        <div class="main-content" id="main-content">
<!--          <el-card class="box-card">-->
            <div>
              <router-view></router-view>
            </div>
<!--          </el-card>-->
        </div>
        </Transition>
        <div class="text-center">
          <Footer/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from './sidebar/Sidebar';
import Header from './header/Header';
import Footer from './footer/Footer';

export default {
  name: 'Layouts',
  components: {
    Sidebar,
    Header,
    Footer,
  },
};
</script>

<style scoped>
.main-content {
  padding: 30px 30px;
  flex: 1;
  margin-left: 50px;
}

.right-side {
  display: flex;
  flex-direction: column;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column-fluid {
  flex: 1 0 auto;
}

@media screen and (max-width: 900px) {
  .main-content {
    margin-left: 60px;
  }
}
.head-of-layout-div {
  min-height: calc(100vh - 40px);

}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
