<template>
<div class="app-footer">
  <p class="mb-0 mt-0">Orko Health Ltd</p>
</div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped>
.app-footer {
  position: absolute;
  width: 100%;
  bottom: -100px;
}
.app-footer p {
  background: #000;
  color: #fff;
  padding: 15px 0px;
  width: 100%;
  display: block;
}
</style>
