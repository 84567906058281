export const responsive = {
  methods: {
    responsive(x) {
      if (x.matches) { // If media query matches
        this.isCollapse = true;
      } else {
        // document.body.style.backgroundColor = "pink";
      }
    },
  },
};
