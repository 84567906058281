<template>
  <el-menu
      default-active="/dashboard"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
      :router="true"
  >

    <div class="flex-grow"/>
    <div class="d-flex justify-content-center align-items-center">
      {{ userInstitute.name }}
    </div>

    <div class="flex-grow"/>
    <el-sub-menu index="2">
      <template #title>
        <el-avatar v-if="authUser.image" :size="40" class="user_avatar">
          <img
              :src="userPhoto(authUser.image)" alt="Orko Health Ltd"
          />
        </el-avatar>
        <el-avatar v-else :size="40" class="user_avatar">
          <img
              src="https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png" alt="Orko Health Ltd"
          />
        </el-avatar>
        {{ authUser ? authUser.fullname : 'profile' }}
      </template>
      <el-menu-item index="/doctors" v-if="checkUsrPermissions('center wise doctor list')">My Doctors</el-menu-item>
      <!--      <el-menu-item index="/my/appointments">My Appointments</el-menu-item>-->
      <el-menu-item index="/medical/records" v-if="checkUsrPermissions('prescription list by agent')">Medical Records
      </el-menu-item>
      <el-menu-item index="/statement" v-if="checkUsrPermissions('wallet transaction details')">My Statement
      </el-menu-item>
      <el-menu-item index="/setting">Settings</el-menu-item>
      <el-menu-item v-loading.fullscreen.lock="fullscreenLoading" index="#" @click="userLogout()">Logout</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
      activeIndex: 1,
      isCollapse: false,
      fullscreenLoading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['authUser', 'userInstitute']),
    // currentUser: {
    //   get() {
    //     return this.$store.state.auth.user ? this.$store.state.auth.user : '';
    //   },
    // },
  },
  methods: {
    ...mapActions('auth', ['logout', 'getUserPermissions', 'getInstitute']),
    userPhoto(img) {
      if (img) {
        return `${process.env.VUE_APP_API_URL}${img}`;
      }
    },
    userLogout() {
      this.fullscreenLoading = true;
      this.logout()
        .then((res) => {
          if (res.data && res.data.status_code == 200) {
            this.fullscreenLoading = false;
            this.$router.push('/login');
          }
        });
    },
  },
  mounted() {
    this.getUserPermissions();
    this.getInstitute();
  },
};
</script>

<style scoped>
.flex-grow {
  flex-grow: 2;
}

.user_avatar {
  margin-right: 10px;
}
</style>
