<template>
  <div class="aside" id="aside">
    <div class="hide-show-icon">
      <el-radio-group v-model="isCollapse" style="margin-bottom: 20px">
        <el-radio-button :label="false" v-if="isCollapse">
          <el-icon>
            <Expand/>
          </el-icon>
        </el-radio-button>
        <el-radio-button :label="true" v-if="!isCollapse">
          <el-icon>
            <Fold/>
          </el-icon>
        </el-radio-button>
      </el-radio-group>
    </div>
    <el-menu
        default-active="/dashboard"
        class="el-menu-vertical-demo sidebar-menu"
        :collapse="isCollapse"
        :router="true"
        id="mySidenav"
        @open="handleOpen"
        @close="handleClose">
      <div class="text-center">
        <img src="../../../assets/logo.png" alt="Orko" class="sidebar-logo" id="sidebar-logo">
      </div>
      <hr>
      <div>
        <el-menu-item index="/dashboard">
          <el-icon>
            <PieChart/>
          </el-icon>
          <template #title>
            Dashboard
          </template>
        </el-menu-item>
        <el-menu-item index="/appointments"
                      v-if="checkUsrPermissions('Book appointment') && authUser.sub_type != 'physio_assistant'">
          <el-icon>
            <font-awesome-icon icon="fa-solid fa-calendar-check"/>
          </el-icon>
          <template #title>
            Book Appointment
          </template>
        </el-menu-item>
        <el-menu-item index="/my/appointments" v-if="checkUsrPermissions('Appointment list New')">
          <el-icon>
            <DocumentAdd/>
          </el-icon>
          <template #title>
            My appointments
          </template>
        </el-menu-item>
        <el-menu-item index="/today/appointments" v-if="checkUsrPermissions('Todays Appointment List for Occ')">
          <el-icon>
            <font-awesome-icon icon="fa-solid fa-calendar-day"/>
          </el-icon>
          <template #title>
            Appointment history
          </template>
        </el-menu-item>
        <el-menu-item index="/therapy-patients" v-if="checkUsrPermissions('get agent prescription therapies')">
          <el-icon>
            <DocumentAdd/>
          </el-icon>
          <template #title>
            Therapy Patients
          </template>
        </el-menu-item>
        <el-menu-item index="/doctors" to="/doctors" v-if="checkUsrPermissions('center wise doctor list')">
          <el-icon>
            <font-awesome-icon icon="fa fa-user-doctor"/>
          </el-icon>
          <template #title>
            Center Doctors
          </template>
        </el-menu-item>
        <el-menu-item index="/outside/doctors" to="/outside/doctors" v-if="checkUsrPermissions('get outside doctors from occ')">
          <el-icon>
            <font-awesome-icon icon="fa fa-user-doctor"/>
          </el-icon>
          <template #title>
            Outside Doctors
          </template>
        </el-menu-item>
        <el-menu-item index="/brokers" to="/brokers" v-if="checkUsrPermissions('get brokers list')">
          <el-icon>
            <font-awesome-icon icon="fa fa-user-secret"/>
          </el-icon>
          <template #title>
            Brokers
          </template>
        </el-menu-item>
        <el-menu-item index="/patients" v-if="checkUsrPermissions('agent get own patients')">
          <el-icon>
            <font-awesome-icon icon="fa fa-bed-pulse"/>
          </el-icon>
          <template #title>
            Patients
          </template>
        </el-menu-item>
        <el-menu-item index="/my-patients?my_paitent=1" v-if="checkUsrPermissions('agent get own patients')">
          <el-icon>
            <font-awesome-icon icon="fa fa-bed-pulse"/>
          </el-icon>
          <template #title>
            My Patients
          </template>
        </el-menu-item>
        <el-menu-item index="/zero-club-foot-patients" v-if="checkUsrPermissions('agent get all cf patients')">
          <el-icon>
            <font-awesome-icon icon="fa fa-baby"/>
          </el-icon>
          <template #title>
            Zero club foot Patients
          </template>
        </el-menu-item>
        <el-menu-item index="/cancer-screening-patients" v-if="checkUsrPermissions('cancer patient list')">
          <el-icon>
            <font-awesome-icon icon="fa fa-bed-pulse"/>
          </el-icon>
          <template #title>
            Cancer screening Patients
          </template>
        </el-menu-item>
        <el-menu-item index="/medical/records" v-if="checkUsrPermissions('prescription list by agent')">
          <el-icon>
            <Comment/>
          </el-icon>
          <template #title>
            Medical Record
          </template>
        </el-menu-item>
        <el-menu-item index="/statement" v-if="checkUsrPermissions('wallet transaction details')">
          <el-icon>
            <Document/>
          </el-icon>
          <template #title>
            Statement
          </template>
        </el-menu-item>
        <el-menu-item index="/invoices" v-if="checkUsrPermissions('Invoice list')">
          <el-icon>
            <Document/>
          </el-icon>
          <template #title>
            Invoices
          </template>
        </el-menu-item>
        <el-menu-item index="/zcf/service/plans" v-if="checkUsrPermissions('ZCF Service Plan list')">
          <el-icon><HelpFilled /></el-icon>
          <template #title>
            ZCF Service Plans
          </template>
        </el-menu-item>
        <el-menu-item index="/welfare-fund" v-if="checkUsrPermissions('Welfare Transaction list')">
          <el-icon><HelpFilled /></el-icon>
          <template #title>
            Welfare Transaction
          </template>
        </el-menu-item>
        <el-menu-item index="/welfare-application" v-if="checkUsrPermissions('Welfare Application list')">
          <el-icon><HelpFilled /></el-icon>
          <template #title>
            Welfare Application
          </template>
        </el-menu-item>
        <el-menu-item index="/referral" v-if="checkUsrPermissions('Referral Bonus list')">
          <el-icon><HelpFilled /></el-icon>
          <template #title>
            Referral
          </template>
        </el-menu-item>
        <el-menu-item index="/disbursements" v-if="checkUsrPermissions('Referral Disbursement List')">
          <el-icon><HelpFilled /></el-icon>
          <template #title>
            Disbursement
          </template>
        </el-menu-item>
        <el-menu-item index="/call/logs" v-if="checkUsrPermissions('Agent Call Log list')">
          <el-icon><Service /></el-icon>
          <template #title>
            Call log 
          </template>
        </el-menu-item>
        <el-menu-item index="/feedbacks">
          <el-icon><ChatDotSquare /></el-icon>
          <template #title>
            Feedback
          </template>
        </el-menu-item>
        <el-menu-item index="/setting">
          <el-icon>
            <Setting/>
          </el-icon>
          <template #title>
            Setting
          </template>
        </el-menu-item>
        <el-menu-item index="/notifications" v-if="checkUsrPermissions('notification list')">
          <el-icon>
            <Bell/>
          </el-icon>
          <template #title>Notification</template>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { responsive } from '../../../mixins/responsive';

export default {
  name: 'Sidebar',
  mixins: [responsive],
  data() {
    return {
      isCollapse: false,
      main_props: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['authUser']),
  },
  created() {
    const x = window.matchMedia('(max-width: 900px)');
    this.responsive(x); // Call listener function at run time
    x.addListener(this.responsive); // Attach listener function on state changes
  },
  methods: {
    handleOpen() {
      document.getElementById('mySidenav').style.width = '250px';
      document.getElementById('main-content').style.marginLeft = '270px';
    },
    handleClose() {
    },

  },
  watch: {
    isCollapse(oldValue, newValue) {
      console.log('old', oldValue, 'new', newValue);
      if (newValue === false) {
        document.getElementById('sidebar-logo').style.height = '25px !important';
        document.getElementById('mySidenav').style.width = '0px !important';
        document.getElementById('main-content').style.marginLeft = '60px';
      }
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid #f4f6f9
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 250px;
  min-height: 400px;
}

.sidebar-menu {
  height: 100vh;
}

.sidebar-logo {
  height: 40px;
  text-align: center;
  margin-top: 6px;
}

.hide-show-icon {
  position: absolute;
  top: 14px;
  right: -70px;
  z-index: 1;

}

.aside {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 98;
  /*overflow-y: scroll;*/
}
</style>
<style>
.sidebar-menu {
  overflow-y: scroll;
}
</style>
